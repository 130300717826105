import './utils/polyfills';
import './utils/sentry';

import theme from '@trmediaab/theme-travrondenspel';
import { ThemeProvider } from '@trmediaab/zebra';
import {
  registerLocale as registerDatePickerLocale,
  setDefaultLocale as setDefaultDatePickerLocale,
} from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components';

import { AdsLoaderProvider } from '~/components/Ad/AdsLoaderContext';
import ErrorBoundary from '~/components/ErrorBoundary';
import svLocale from '~/utils/svDateLocale';

import Page from './components/Page';
import ConsentBannerStyles from './main/consentBannerStyles';
import GlobalCSS from './main/global-styles';
import { shouldForwardProp } from './main/shouldForwardProp';
import store from './main/store';

registerDatePickerLocale('sv', svLocale);
setDefaultDatePickerLocale('sv');

const root = createRoot(document.querySelector('#root'));

root.render(
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <ThemeProvider theme={theme}>
      <GlobalCSS />
      <ErrorBoundary coverFullScreen>
        <Provider store={store}>
          <ConsentBannerStyles />
          <AdsLoaderProvider>
            <HelmetProvider>
              <Page />
            </HelmetProvider>
          </AdsLoaderProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </StyleSheetManager>,
);
