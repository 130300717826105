import { Text } from '@trmediaab/zebra';

import { ThemedLink } from '../ThemedLink';

const UNDERLINE_STYLE = {
  position: 'absolute',
  content: '""',
  bottom: '8px',
  display: 'block',
  height: '3px',
  backgroundColor: 'brands.travrondenSpel',
};

export function NavLink({ to, isActive, children, ...rest }) {
  return (
    <ThemedLink
      to={to}
      fontSize={1}
      textTransform="uppercase"
      fontWeight="bold"
      color="brands.travrondenSpel"
      px={2}
      sx={{
        position: 'relative',
        '&:hover, &:active, &:focus': {
          color: 'brands.travrondenSpel',
        },
        '&:hover:after, &:active:after, &:focus:after': {
          ...UNDERLINE_STYLE,
          left: 2,
          right: 2,
        },
        ...(isActive && {
          '&:after': {
            ...UNDERLINE_STYLE,
            left: 2,
            right: 2,
          },
        }),
      }}
      {...rest}
    >
      {children}
    </ThemedLink>
  );
}

export function DropdownLink({ isActive, children, ...rest }) {
  return (
    <Text
      fontSize={1}
      textTransform="uppercase"
      fontWeight="bold"
      color="brands.travrondenSpel"
      px={2}
      textAlign="left"
      as="button"
      {...rest}
    >
      {children}
    </Text>
  );
}
export function SubNavItem({ text, to, ...rest }) {
  return (
    <ThemedLink
      to={to}
      fontSize={1}
      textTransform="uppercase"
      fontWeight="bold"
      color="brands.travrondenSpel"
      px="2"
      py="2,5"
      sx={{
        display: 'block',
        '&:hover, &:active, &:focus': {
          color: 'brands.travrondenSpel',
        },
        "&:hover [data-id='link-text']:after": {
          content: '""',
          position: 'absolute',
          bottom: '-3px',
          left: '0px',
          right: '0px',
          height: '3px',
          backgroundColor: 'brands.travrondenSpel',
        },
      }}
      {...rest}
    >
      <Text
        width="min-content"
        data-id="link-text"
        sx={{
          position: 'relative',
        }}
      >
        {text}
      </Text>
    </ThemedLink>
  );
}
