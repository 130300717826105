import { produce } from 'immer';

import { AUTH_SUCCESS } from './actions';
import truth from './truth';

const initialState = {
  user: truth.user,
  userUpdated: false,
};

const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    if (action.type === AUTH_SUCCESS) {
      draft.user = action.user;
      draft.userUpdated = true;
    }
  });

export default Reducer;
