import { fnv32a } from '@trmediaab/zebra-utils';
import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

import {
  FETCH_GENERIC_FAILURE,
  FETCH_GENERIC_START,
  FETCH_GENERIC_SUCCESS,
  UPDATE_GENERIC_CACHE,
} from './actions';

const initialState = {
  __cache__: {},
};

const mergeState = (state, model, key, obj) => {
  const current = state[model]?.[key];
  state[model] = {
    ...state[model],
    [key]: current != null ? { ...current, ...obj } : obj,
  };
};

const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_GENERIC_START:
        mergeState(draft, action.model, action.key, {
          error: undefined,
          fetching: true,
        });
        break;

      case FETCH_GENERIC_SUCCESS: {
        const currentData = draft[action.model]?.[action.key]?.data || {};
        let nextData = action.data;

        // Merge current and next results arrays
        if (
          nextData != null &&
          Array.isArray(nextData.results) &&
          Array.isArray(currentData.results)
        ) {
          nextData = {
            ...currentData,
            ...nextData,
            results: uniqWith(
              [...nextData.results, ...currentData.results],
              (a, b) => a.id === b.id || isEqual(a, b),
            ),
          };
        }

        mergeState(draft, action.model, action.key, {
          fetching: false,
          data: nextData,
        });

        if (action.id) {
          draft[action.model][action.key].extra_id = action.id;
        }

        break;
      }

      case FETCH_GENERIC_FAILURE:
        mergeState(draft, action.model, action.key, {
          fetching: false,
          error: action.error,
        });
        break;

      case UPDATE_GENERIC_CACHE:
        // Note: we store a hash of the url to minimize length of state keys
        draft.__cache__[fnv32a(action.url)] = Date.now();
        break;

      default:
      // Do nothing
    }
  });

export default Reducer;
