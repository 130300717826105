import { Anchor } from '@trmediaab/zebra';
import { getHours, isWeekend } from 'date-fns';
import Link from 'redux-first-router-link';

export const LinkItem = ({
  setOpen,
  fontSize,
  activeStyles,
  isActive,
  ...anchorProps
}) => (
  <Anchor
    variant="matchText"
    fontWeight="semiBold"
    onClick={() => {
      if (setOpen != null) {
        setOpen(false);
      }
    }}
    fontSize={fontSize ?? '15px'}
    as={Link}
    css={`
      &:hover,
      &:focus,
      &:active {
        transition: 0;
        color: black !important;
      }
      border-bottom: 2px solid transparent;
      ${activeStyles &&
      `
        &:hover, &:focus, &:active {
            border-bottom: 2px solid black;
        }
      `}
      ${isActive && 'border-bottom:2px solid black;'}
    `}
    {...anchorProps}
  />
);

export const isV4Lunch = (betType, startTime) => {
  const date = new Date(startTime);

  return betType === 'V4' && getHours(date) <= 13 && !isWeekend(date);
};
