import { Box, Text } from '@trmediaab/zebra';
import { useWindowEvents } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { getWindowSize } from '../../utils/dom';
import { ADS_PREFIX, PLACEMENTS, STATUS, UNIT_CONFIGS } from './constants';
import Slot from './Slot';

const UNITS = Object.keys(PLACEMENTS).reduce((acc, placement) => {
  let sizes, sizeMapping;

  if (placement !== 'Takeover') {
    const sizeKey = placement.toLowerCase().match(/^\S+/)[0];
    const config = UNIT_CONFIGS[sizeKey];
    let hasSizeMapping;

    [sizes, hasSizeMapping] = config.reduce(
      (acc2, { sizes, viewport }) => {
        acc2[0].push(...sizes);
        if (Array.isArray(viewport)) {
          acc2[1] = true;
        }
        return acc2;
      },
      [[], false],
    );

    if (hasSizeMapping) {
      sizeMapping = config;
    }
  }

  const unit = {
    name: `${ADS_PREFIX}_${PLACEMENTS[placement].replace(' ', '_')}`,
    sizes,
    sizeMapping,
  };

  return {
    ...acc,
    [placement]: unit,
  };
}, {});

const Ad = ({
  placement,
  showLabel = true,
  wrap = content => content,
  deviceFilter = 'none',
  refreshKey = null,
}) => {
  const windowSize = useRef(getWindowSize());
  const [status, setStatus] = useState(
    import.meta.env.VITE_ADS_ENABLED !== 'false'
      ? STATUS.INITIAL
      : STATUS.FILLED,
  );
  const [visible, setVisible] = useState(false);

  const checkVisibility = useCallback(() => {
    if (
      deviceFilter === 'mobileOnly' &&
      windowSize.current?.breakpoints?.giant
    ) {
      return setVisible(false);
    }

    // Skip desktop only on smaller screen
    if (
      deviceFilter === 'desktopOnly' &&
      !windowSize.current?.breakpoints?.giant
    ) {
      return setVisible(false);
    }

    if (windowSize.current?.width !== getWindowSize().width) {
      windowSize.current = getWindowSize();
    }

    setVisible(true);
  }, [deviceFilter]);

  useEffect(() => {
    checkVisibility();
  }, [checkVisibility]);

  useWindowEvents('resize', checkVisibility);

  if (!visible) {
    return null;
  }

  const { name, sizes, sizeMapping } = UNITS[placement];

  let slot;

  if (import.meta.env.VITE_ADS_ENABLED !== 'false') {
    slot = (
      <Slot
        name={name}
        sizes={sizes}
        sizeMapping={sizeMapping}
        onStatusChange={setStatus}
        renderOutOfThePage={placement === 'Takeover'}
        refreshKey={refreshKey}
      />
    );
  } else {
    // Render a placeholder when ads are disabled, except for the
    // takeover where a placeholder doesn't make sense
    if (placement === 'Takeover') {
      slot = null;
    } else {
      slot = (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="greys.1"
          width={`${sizes[sizes.length - 1][0]}px`}
          height={`${sizes[sizes.length - 1][1]}px`}
          maxWidth="100%"
          opacity="0.4"
        >
          <Text color="greys.2" fontWeight="semiBold" textAlign="center">
            {placement}
            <br />
            {sizes[sizes.length - 1].join('x')}
          </Text>
        </Box>
      );
    }
  }

  const cssVisibility = `
    visibility: ${
      status === STATUS.INITIAL
        ? 'hidden'
        : status === STATUS.FILLED
          ? 'visible'
          : 'initial'
    };
    display: ${
      status === STATUS.EMPTY
        ? 'none'
        : placement === 'Takeover'
          ? 'block'
          : 'flex'
    };
  `;

  if (placement === 'Takeover') {
    // The className is referenced by the creative template in Google Ad Manager
    return (
      <Box
        className="takeover-ad"
        css={`
          /*
            Note:
            Commented for now because it looks like we get a filled status
            first (onSlotRenderEnded event.isEmpty is false) and then
            empty status (onSlotRenderEnded event.isEmpty is true). This
            causes the iframe to be shown then hidden and with the body
            stuck in position fixed.
            ${cssVisibility};
            height: ${status !== STATUS.FILLED ? 0 : 'initial'};
          */
          iframe {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 100000;
          }
        `}
      >
        {slot}
      </Box>
    );
  }

  return wrap(
    <Box justifyContent="center" css={cssVisibility}>
      {showLabel ? (
        <Box>
          <Text
            fontSize="10px"
            lineHeight="1"
            fontWeight="medium"
            color="greys.3"
            mb="8px"
          >
            Annons
          </Text>
          {slot}
        </Box>
      ) : (
        slot
      )}
    </Box>,
    status === STATUS.FILLED,
  );
};

Ad.propTypes = {
  placement: PropTypes.oneOf(Object.keys(PLACEMENTS)).isRequired,
  showLabel: PropTypes.bool,
  wrap: PropTypes.func,
  deviceFilter: PropTypes.oneOf(['none', 'mobileOnly', 'desktopOnly']),
  refreshKey: PropTypes.string,
};

export default memo(Ad);
