import { all, call, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { apiUrl } from '~/utils/request';

import { NAMESPACE } from './constants';
import { selectRace } from './selectors';

export function* getMeet({ payload: { date, track } }) {
  const slug = `${track}-${date}`;

  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`meet/${slug}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `meet-${slug}`,
  });
}

export function* getRace({ payload: { race_number, date, track } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${track}-${date}-${race_number}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `race-${track}-${date}-${race_number}`,
  });

  const race = yield select(selectRace, {
    id: `race-${track}-${date}-${race_number}`,
  });

  if (race?.has_result) {
    yield call(getRaceResults, {
      date: race.meet.race_date,
      track: race.meet.track_slug,
      race_number: race.race_number,
    });
  }
}

export function* getRaceResults({ date, track, race_number }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${track}-${date}-${race_number}/results`),
    key: `result-${track}-${date}-${race_number}`,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery('RACE_RESULT', getRace),
    takeLatest('RACE_RESULT', getMeet),
    takeEvery('RACE', getRace),
    takeLatest('RACE', getMeet),
  ]);
}
