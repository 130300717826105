import { Divider, FlexBox, Position, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import { ThemedLink } from '~/components/ThemedLink';

import { desktopItems, sideItems } from '../items';
import { DropdownLink, SubNavItem } from '../NavLink';
import { LinkItem } from '../utils';

const UserMenu = ({ closeMenu, children }) => {
  const [showRoundsMenu, setShowRoundsMenu] = useState(false);

  return (
    <Position.fixed
      top="0"
      height="100%"
      width="100%"
      bg="white"
      zIndex="9999999"
      overflow="auto"
      css={`
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        ::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      {children}

      <FlexBox display="flex" flexDirection="column" p="3" pt="0">
        {desktopItems.map(({ text, to, items }) =>
          to ? (
            <Fragment key={text}>
              <ThemedLink to={to} onClick={closeMenu} py="3">
                <Text
                  color="brands.travrondenSpel"
                  fontSize={2}
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {text}
                </Text>
              </ThemedLink>

              <Divider bg="greys.1" opacity={0.5} />
            </Fragment>
          ) : (
            <Fragment key={text}>
              <DropdownLink
                to={to}
                onClick={() => setShowRoundsMenu(state => !state)}
                py="3"
                px="0"
              >
                <Text
                  color="brands.travrondenSpel"
                  fontSize={2}
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {text}
                </Text>
              </DropdownLink>

              {showRoundsMenu &&
                items.map((item, index) => (
                  <SubNavItem
                    key={item.text}
                    text={item.text}
                    to={item.to}
                    onClick={closeMenu}
                    mb={index === items.length - 1 ? '3,5' : undefined}
                    fontSize={2}
                  />
                ))}

              <Divider bg="greys.1" opacity={0.5} />
            </Fragment>
          ),
        )}
      </FlexBox>

      <FlexBox display="flex" flexDirection="column" p="3" pt="0">
        {sideItems.map(({ text, to }) => (
          <LinkItem
            key={text}
            to={to}
            fontSize="2"
            onClick={closeMenu}
            py="1"
            color="brands.travrondenSpel"
          >
            {text}
          </LinkItem>
        ))}
      </FlexBox>
    </Position.fixed>
  );
};

UserMenu.propTypes = {
  activeRounds: PropTypes.object,
  children: PropTypes.node.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default UserMenu;
