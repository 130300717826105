import createCachedSelector from 're-reselect';

import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

export const getInterviewArchive = (substate, props) => {
  let items = [];
  let count = 0;
  let pages = 0;

  const result = substate?.[props.race_start_time]?.[props.horse_id];

  if (result) {
    count = result.count;

    for (const page in result.pages) {
      if (
        Number.parseInt(page, 10) <= props.page &&
        result.pages.hasOwnProperty(page)
      ) {
        // eslint-disable-next-line unicorn/prefer-spread
        items = items.concat(result.pages[page]);
        pages++;
      }
    }
  }

  if (props.page === pages) {
    return {
      results: items,
      count: count,
    };
  }

  return;
};

const getKey = props => `${props.slug}-${props.leg}`;

export const selectInterview = createGenericSelector(NAMESPACE, 'data', getKey);
export const selectError = createGenericSelector(NAMESPACE, 'error', getKey);
export const selectLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  getKey,
);

export const createInterviewArchiveSelector = (
  model,
  getKey = props => props.slug,
) =>
  createCachedSelector(
    (_, props) => props,
    state => state.interviewArchive[model] || undefined,
    (props, substate) =>
      substate ? getInterviewArchive(substate, props) : undefined,
  )((_, props) => `${model}:${getKey(props)}`);

const getArchiveKey = props =>
  `${props.horse_id}-${props.race_start_time}-${props.page}`;

export const selectInterviewArchive = createInterviewArchiveSelector(
  NAMESPACE,
  getArchiveKey,
);
