import { localStorage } from '@trmediaab/zebra-utils';
import { produce } from 'immer';

import { LIVE_FEED_VISIT } from './constants';

const items = localStorage.get('live_feed_visit');

const initialState = {
  live_feed_visit: items || {},
};

const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    if (action.type === LIVE_FEED_VISIT) {
      draft.live_feed_visit[action.slug] = new Date();
      localStorage.set('live_feed_visit', draft.live_feed_visit);
    }
  });

export default Reducer;
