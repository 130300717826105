import isPlainObject from 'lodash/isPlainObject';
import { takeEvery } from 'redux-saga/effects';

import routesMap from '~/main/routes';

import { apiUrl } from '../../utils/request';
import { fetchGeneric } from '../GenericFetch/saga';
import { NAMESPACE } from './constants';
import { getPageKey } from './selectors';

function transformContent(res, filter = {}) {
  if (
    ![
      isPlainObject(res),
      Array.isArray(res.results),
      filter.hasOwnProperty('limit'),
      filter.hasOwnProperty('offset'),
    ].every(Boolean)
  ) {
    return res;
  }

  const { results } = res;

  return {
    ...res,
    [getPageKey(filter)]: results.map(item => item.id),
  };
}

export function* getContentBySlug({ payload: { slug } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`content/${slug}`, {
      show_round: true,
    }),
    maxAge: '10minutes',
    key: slug,
    show_round: true,
  });
}

export function* getContentList(location) {
  const { type, payload } = location;

  const {
    listModel: model,
    listLimit: limit,
    contentType,
    detail = false,
  } = routesMap[type].meta || {};

  if (model == null) {
    console.warn(`Couldn't determine model for route ${type}`);
    return;
  }

  const page = payload.page || 1;
  const offset = limit * (page - 1);
  const bet_type = payload.bet_type;
  const round_slug = payload.round_slug ?? payload.slug;
  const key = round_slug
    ? `${model}-${round_slug}`
    : bet_type
      ? `${model}-${bet_type}`
      : model;

  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`content`, {
      type: contentType,
      limit,
      offset,
      ordering: '-publish_start',
      detail,
      show_round: true,
      bet_type: bet_type != null ? bet_type.toUpperCase() : undefined,
      round_slug,
    }),
    maxAge: '5minutes',
    responseHandler: res => transformContent(res, { limit, offset }),
    key,
  });
}

export function* getContentListByRoundSlug(location) {
  const { payload } = location;

  const bet_type = payload.bet_type;
  const round_slug = payload.round_slug ?? payload.slug;
  const key = round_slug;

  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`content`, {
      ordering: '-publish_start',
      bet_type: bet_type != null ? bet_type.toUpperCase() : undefined,
      round_slug,
      limit: 50,
      show_round: true,
      detail: true,
    }),
    maxAge: '5minutes',
    responseHandler: res => transformContent(res),
    key,
  });
}

export default function* rootSaga() {
  yield takeEvery(
    [
      'NEWS',
      'CHRONICLE',
      'PAGE',
      'TV',
      'BET_TYPE',
      'ABOUT_PAGE',
      'ROUND_ANALYSIS',
      'ROUND_NEWS',
      'ROUND_CHRONICLE',
      'ROUND_TV',
    ],
    getContentBySlug,
  );
  yield takeEvery(
    [
      'BET_TYPE',
      'CHRONICLE',
      'CHRONICLE_LIST',
      'NEWS',
      'NEWS_LIST',
      'SHARED_BETS',
      'TV',
      'TV_LIST',
      'ROUND_NEWS_LIST',
      'ROUND_CHRONICLE_LIST',
      'ROUND_TV_LIST',
    ],
    getContentList,
  );
  yield takeEvery(
    [
      'ROUND_TIP',
      'ROUND_NEWS',
      'ROUND_NEWS_LIST',
      'ROUND_CHRONICLE',
      'ROUND_CHRONICLE_LIST',
      'ROUND_TV',
      'ROUND_TV_LIST',
    ],
    getContentListByRoundSlug,
  );
}
