import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { takeLeading } from 'redux-saga/effects';

import { apiUrl } from '../../utils/request';
import { fetchGeneric } from '../GenericFetch/saga';
import { LOAD_KEY_START_RACE, NAMESPACE } from './constants';

function groupKeyStartRace(resp) {
  let result = groupBy(resp, 'type');
  result = isEmpty(result) ? null : result;

  return result;
}

export function* getKeyStartRace({ race }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${race}/key_starts`),
    maxAge: Number.POSITIVE_INFINITY,
    responseHandler: resp => groupKeyStartRace(resp),
    key: race,
  });
}

export default function* rootSaga() {
  yield takeLeading(LOAD_KEY_START_RACE, getKeyStartRace);
}
