import { produce } from 'immer';

import { liveFeedVisit } from '~/containers/LiveFeed/actions';
import liveFeedReducer from '~/containers/LiveFeed/reducer';

const initialState = {};

const getNestedProperty = (obj, chain) =>
  chain.reduce((acc, key) => acc && acc[key], obj);

const setNestedProperty = (obj, chain, value) => {
  chain.reduce((acc, key, index) => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (index === chain.length - 1) {
      acc[key] = value; // Set the final value
    } else {
      acc[key] = acc[key] || {}; // Ensure intermediate objects exist
    }
    return acc[key];
  }, obj);
};

const updateStartProps = (state, data, chain, key, value) => {
  const race = getNestedProperty(state, chain);
  if (race && race.id && race.id === data.race_id) {
    race.starts.forEach(start => {
      if (start.id === data.start_id) {
        start.odds[key] = value; // TODO odds when updating game percent??
      }
    });
  }
};

const updateRaceReducer = (state, data, key_prop, value_prop) => {
  if (state.Race) {
    Object.keys(state.Race).forEach(key => {
      updateStartProps(
        state,
        data,
        ['Race', key, 'data'],
        data[key_prop],
        data[value_prop],
      );
    });
  }

  if (state.RoundLeg) {
    Object.keys(state.RoundLeg).forEach(key => {
      updateStartProps(
        state,
        data,
        ['RoundLeg', key, 'data', 'race'],
        data[key_prop],
        data[value_prop],
      );
    });
  }
};

const updateInterviews = (state, data) => {
  if (state.Interview) {
    Object.keys(state.Interview).forEach(slug => {
      if (data.instance.race_id === state.Interview[slug].extra_id) {
        if (data.new) {
          // append to list
          const array = state.Interview[slug].data;
          array.unshift(data.instance);
          setNestedProperty(state, ['Interview', slug, 'data'], array);
        } else {
          // update it
          const index = state.Interview[slug].data.findIndex(
            item => item.id === data.instance.id,
          );
          if (index !== -1) {
            setNestedProperty(
              state,
              ['Interview', slug, 'data', index],
              data.instance,
            );
          }
        }
      }
    });
  }
};

const updateLiveFeed = (state, data) => {
  if (state.LiveFeed) {
    Object.keys(state.LiveFeed).forEach(slug => {
      if (data.instance.round_slug === slug) {
        if (data.new) {
          // append to list
          const array = state.LiveFeed[slug].data;
          array.unshift(data.instance);
          setNestedProperty(state, ['LiveFeed', slug, 'data'], array);
        } else {
          // update it
          const index = state.LiveFeed[slug].data.findIndex(
            item =>
              item.id === data.instance.id &&
              item.content_type === data.instance.content_type,
          );
          if (index !== -1) {
            setNestedProperty(
              state,
              ['LiveFeed', slug, 'data', index],
              data.instance,
            );
          }
        }
      }
    });
  }
};

const updateRound = (state, data) => {
  if (state.Round) {
    Object.keys(state.Round).forEach(slug => {
      if (data.slug === slug) {
        // found it, update the round
        setNestedProperty(state, ['Round', slug, 'data'], data);
      }
    });
  }
};

const updateRace = (state, data) => {
  if (state.Race) {
    Object.keys(state.Race).forEach(key => {
      const race = state.Race[key].data;
      if (race && race.id && race.id === data.id) {
        setNestedProperty(state, ['Race', key, 'data'], data);
      }
    });
  }
  if (state.RoundLeg) {
    Object.keys(state.RoundLeg).forEach(key => {
      const roundleg = state.RoundLeg[key].data;
      if (
        roundleg &&
        roundleg.race &&
        roundleg.race.id &&
        roundleg.race.id === data.id
      ) {
        setNestedProperty(state, ['RoundLeg', key, 'data', 'race'], data);
      }
    });
  }
};

const updateStart = (state, data) => {
  if (state.Race) {
    Object.keys(state.Race).forEach(key => {
      const race = state.Race[key].data;
      if (race && race.id && race.id === data.race_id) {
        race.starts.forEach((start, i) => {
          if (start && start.id && start.id === data.id) {
            setNestedProperty(state, ['Race', key, 'data', 'starts', i], data);
          }
        });
      }
    });
  }
  if (state.RoundLeg) {
    Object.keys(state.RoundLeg).forEach(key => {
      const roundleg = state.RoundLeg[key].data;
      if (
        roundleg &&
        roundleg.race &&
        roundleg.race.id &&
        roundleg.race.id === data.race_id
      ) {
        roundleg.race.starts.forEach((start, i) => {
          if (start && start.id && start.id === data.id) {
            setNestedProperty(
              state,
              ['RoundLeg', key, 'data', 'starts', i],
              data,
            );
          }
        });
      }
    });
  }
};

const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'ODDS_UPDATE':
        updateRaceReducer(draft.generic, action.data, 'provider', 'odds');
        break;

      case 'GAME_PERCENT_UPDATE':
        updateRaceReducer(draft.generic, action.data, 'bet_type', 'percent');
        break;

      case 'LIVE_UPDATE': {
        const { type, payload } = draft.location;
        const slug = action.data.instance.round_slug;
        let { liveFeed } = draft;

        if (
          liveFeed &&
          slug &&
          type &&
          type === 'ROUND_TIP' &&
          payload.view === 'live' &&
          payload.slug === slug
        ) {
          liveFeed = liveFeedReducer(liveFeed, liveFeedVisit(slug));
        }

        draft.liveFeed = liveFeed;
        updateLiveFeed(draft.generic, action.data);
        break;
      }

      case 'INTERVIEW_UPDATE':
        updateInterviews(draft.generic, action.data);
        break;

      case 'ROUND_UPDATE':
        updateRound(draft.generic, action.data);
        break;

      case 'START_UPDATE':
        updateStart(draft.generic, action.data);
        break;

      case 'RACE_UPDATE':
        updateRace(draft.generic, action.data);
        break;

      default:
      // do nothing
    }
  });

export default Reducer;
