import { lazy, Suspense } from 'react';
import { NOT_FOUND } from 'redux-first-router';

import ErrorBoundary from '~/components/ErrorBoundary';
import Spinner from '~/components/Spinner';

const components = {
  Frontpage: lazy(() => import('../containers/Frontpage')),
  Result: lazy(() => import('../containers/Result')),
  BetTypeList: lazy(() => import('../containers/BetTypeList')),
  RaceResult: lazy(() => import('../containers/RaceResult')),
  RoundResult: lazy(() => import('../containers/RoundResult')),
  RoundTipStart: lazy(() => import('../containers/RoundTipStart')),
  RoundTip: lazy(() => import('../containers/RoundTip')),
  RoundListPage: lazy(() => import('../containers/RoundListPage')),
  RoundArticlePage: lazy(() => import('../containers/RoundArticlePage')),
  RoundResultList: lazy(() => import('../containers/RoundResultList')),
  ArticlePage: lazy(() => import('../containers/ArticlePage')),
  ArticleListPage: lazy(() => import('../containers/ArticleListPage')),
  AuthorPage: lazy(() => import('../containers/AuthorPage')),
  AllStarts: lazy(() => import('../containers/AllStarts')),
  NotFound: lazy(() => import('../containers/NotFound')),
  SharedBets: lazy(() => import('../containers/SharedBets')),
  ProductsPage: lazy(() => import('../containers/ProductsPage')),
  ProductsPageCheckout: lazy(
    () => import('../containers/ProductsPage/Checkout'),
  ),
};

const sidebars = {
  FrontpageSidebar: lazy(
    () => import('../containers/Frontpage/FrontpageSidebar'),
  ),
};

const route = ({
  component,
  pageBackgroundVariant = 'secondary',
  pageVariant = 'default',
  panoramaEnabled = true,
  sidebar = null,
  ...rest
}) => {
  const Component = components[component];
  const Sidebar = sidebars[sidebar];

  return {
    ...rest,
    coerceNumbers: true,
    pageBackgroundVariant,
    pageVariant,
    panoramaEnabled,
    component: props => (
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    sidebar: sidebar
      ? props => (
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Sidebar {...props} />
            </Suspense>
          </ErrorBoundary>
        )
      : null,
  };
};

const routesMap = {
  HOME: route({
    path: '/',
    component: 'Frontpage',
    sidebar: 'FrontpageSidebar',
  }),

  RACE: route({
    path: String.raw`/lopp/:track([a-z,\-]+)/:date(\d{4}-\d{2}-\d{2})/:race_number(\d{1,2})`,
    component: 'RaceResult',
  }),

  RESULT: route({
    path: String.raw`/resultat/:date(\d{4}-\d{2}-\d{2})?/`,
    component: 'Result',
  }),

  RACE_RESULT: route({
    path: String.raw`/resultat/:track([a-z,\-]+)/:date(\d{4}-\d{2}-\d{2})/:race_number(\d{1,2})`,
    component: 'RaceResult',
  }),

  ROUND_RESULT: route({
    path: String.raw`/resultat/:slug/:leg_number(\d{1,2})?`,
    component: 'RoundResult',
  }),

  BET_TYPE_LIST: route({
    path: '/:bet_type(V75|V86|GS75|V65|V64)',
    component: 'BetTypeList',
  }),

  SHARED_BETS: route({
    path: '/andelsspel',
    component: 'SharedBets',
    meta: {
      listModel: 'sharedBetsList',
      listLimit: 1,
      detail: true,
      contentType: 'shared_bet',
    },
  }),

  AUTHOR: route({
    path: '/skribent/:slug',
    component: 'AuthorPage',
    panoramaEnabled: false,
  }),

  TV: route({
    path: '/tv/:slug',
    component: 'ArticlePage',
    meta: {
      listModel: 'tvList',
      listLimit: 12,
      contentType: 'tv',
    },
  }),

  CHRONICLE: route({
    path: '/kronikor/:slug',
    component: 'ArticlePage',
    meta: {
      listModel: 'chronicleList',
      listLimit: 12,
      contentType: 'chronicle',
    },
    panoramaEnabled: false,
  }),

  CHRONICLE_LIST: route({
    path: String.raw`/:bet_type?/kronikor/(sida)?/:page(\d+)?`,
    component: 'ArticleListPage',
    meta: {
      listModel: 'chronicleList',
      listLimit: 12,
      contentType: 'chronicle',
    },
  }),

  NEWS: route({
    path: '/nyheter/:slug',
    component: 'ArticlePage',
    meta: {
      listModel: 'newsList',
      listLimit: 12,
      contentType: 'news_article',
    },
  }),

  NEWS_LIST: route({
    path: String.raw`/:bet_type?/nyheter/(sida)?/:page(\d+)?`,
    component: 'ArticleListPage',
    meta: {
      listModel: 'newsList',
      listLimit: 12,
      contentType: 'news_article',
    },
  }),

  ROUND_NEWS_LIST: route({
    path: String.raw`/:bet_type/:slug/nyheter`,
    component: 'RoundListPage',
    meta: {
      listModel: 'newsList',
      listLimit: 12,
      contentType: 'news_article',
    },
  }),

  ROUND_CHRONICLE_LIST: route({
    path: String.raw`/:bet_type/:slug/kronikor`,
    component: 'RoundListPage',
    meta: {
      listModel: 'chronicleList',
      listLimit: 12,
      contentType: 'chronicle',
    },
  }),

  ROUND_TV_LIST: route({
    path: String.raw`/:bet_type/:slug/tv`,
    component: 'RoundListPage',
    meta: {
      listModel: 'tvList',
      listLimit: 12,
      contentType: 'tv',
    },
  }),

  ALL_STARTS: route({
    path: '/till-start',
    component: 'AllStarts',
  }),

  ABOUT_PAGE: route({
    path: '/om/:slug',
    component: 'ArticlePage',
    panoramaEnabled: false,
  }),

  PRODUCTS_PAGE: route({
    path: '/prenumerera',
    component: 'ProductsPage',
    panoramaEnabled: false,
    pageVariant: 'stripped',
  }),

  CHECKOUT_PAGE: route({
    path: '/checkout/:skuOrCampaignId',
    component: 'ProductsPageCheckout',
    panoramaEnabled: false,
    pageVariant: 'stripped',
  }),

  ROUND_RESULTS: route({
    path: '/:bet_type(v75|v64|v65|v86|gs75|v4)/resultat',
    component: 'RoundResultList',
  }),

  ROUND_TIP: route({
    path: String.raw`/:bet_type/:slug/:view?/:leg_number(\d{1,2})?`,
    component: 'RoundTip',
  }),

  ROUND_CHRONICLE: route({
    path: '/:bet_type/:round_slug/kronikor/:slug',
    component: 'RoundArticlePage',
    meta: {
      contentType: 'chronicle',
    },
  }),

  ROUND_NEWS: route({
    path: '/:bet_type/:round_slug/nyheter/:slug',
    component: 'RoundArticlePage',
    meta: {
      contentType: 'news',
    },
  }),

  ROUND_TV: route({
    path: '/:bet_type/:round_slug/tv/:slug',
    component: 'RoundArticlePage',
    meta: {
      contentType: 'tv',
    },
  }),

  ROUND_TIP_START: route({
    path: '/:bet_type/:slug',
    component: 'RoundTipStart',
  }),

  ROUND_TIP_DEPRECATED: route({
    path: String.raw`/:bet_type/:bet_type-startlista/:slug?/:view?/:leg_number(\d{1,2})?`,
    thunk: (dispatch, getState) => {
      const {
        location: {
          payload: { slug, leg_number, view },
        },
      } = getState();

      const bet_type = slug.split('-')[0].toLowerCase();

      if (bet_type) {
        return dispatch({
          type: 'ROUND_TIP',
          payload: {
            bet_type,
            slug,
            view,
            leg_number: leg_number || 1,
          },
        });
      }

      return null;
    },
    component: 'NotFound',
    pageBackgroundVariant: 'secondary',
  }),

  TV_LIST: route({
    path: String.raw`/:bet_type?/tv/(sida)?/:page(\d+)?`,
    component: 'ArticleListPage',
    meta: {
      listModel: 'tvList',
      listLimit: 12,
      contentType: 'tv',
    },
  }),

  PAGE: route({
    path: '/:slug',
    component: 'ArticlePage',
    panoramaEnabled: false,
  }),

  /** Wildcard routes, e.g. { path: '*', ... } (should go last) */
  [NOT_FOUND]: route({
    path: '*',
    component: 'NotFound',
    panoramaEnabled: false,
  }),
};

export default routesMap;
