import {
  FETCH_FEED_FAILURE,
  FETCH_FEED_STARTED,
  FETCH_FEED_SUCCESS,
} from './constants';

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default function reducer(state = initialState, action) {
  if (action.type === FETCH_FEED_STARTED) {
    return {
      ...state,
      error: null,
      isFetching: true,
    };
  }

  if (action.type === FETCH_FEED_SUCCESS) {
    return {
      data: action.response,
      error: null,
      isFetching: false,
    };
  }

  if (action.type === FETCH_FEED_FAILURE) {
    return {
      ...state,
      error: action.error,
      isFetching: false,
    };
  }

  return state;
}
