import { isEmpty } from 'lodash';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import routesMap from '../../main/routes';
import { apiUrl, request } from '../../utils/request';
import {
  fetchFeedFailure,
  fetchFeedStarted,
  fetchFeedSuccess,
} from './actions';
import { getFeed, getIsFetching } from './selectors';

const POLLING_START = 'feed/POLLING_START';
const POLLING_STOP = 'feed/POLLING_STOP';
const POLL_INTERVAL = 60 * 1000 * 10; // Poll once every ten minutes

function* fetchContentFeed() {
  const isInitial = isEmpty(yield select(getFeed));
  const loading = yield select(getIsFetching);

  if (loading) {
    return;
  }

  if (isInitial) {
    yield put(fetchFeedStarted());

    try {
      const response = yield call(request, apiUrl(`content/block/feed`));
      yield put(fetchFeedSuccess(response));
    } catch (error) {
      yield put(fetchFeedFailure(error));
    }
  }
}

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

function* poll() {
  try {
    while (true) {
      yield call(fetchContentFeed);
      yield call(delay, POLL_INTERVAL);
    }
  } finally {
    if (yield cancelled()) {
      import.meta.env.DEV && console.info('Poll loop cancelled');
    }
  }
}

function* onRouteChange({ type }) {
  yield type === 'HOME'
    ? put({ type: POLLING_START })
    : put({ type: POLLING_STOP });
}

export default function* rootSaga() {
  yield takeLatest(Object.keys(routesMap), onRouteChange);

  while (yield take(POLLING_START)) {
    // Start the task in the background
    const pollingTask = yield fork(poll);
    // Wait for stop action
    yield take(POLLING_STOP);
    // This will throw a SagaCancellationException into the forked task
    yield cancel(pollingTask);
  }
}
