import debounce from 'lodash/debounce';

import { breakpoints } from '~/utils/media';

/**
 * Runs `fn` `timeout` milliseconds after the window size stops changing.
 */
export function onWindowResize(fn, timeout = 250) {
  if (typeof window === 'undefined') {
    return;
  }

  const wrapper = debounce(fn, timeout);
  const options = { capture: true, passive: true };

  const events = ['resize', 'orientaionchange'];

  events.forEach(eventName => {
    window.addEventListener(eventName, wrapper, options);
  });

  return () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, wrapper, options);
    });
  };
}

export const getWindowSize = () => {
  if (typeof window === 'undefined') {
    return {
      unknown: true,
      breakpoints: {},
    };
  }

  const width = window.innerWidth || document.documentElement.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight;

  const bpMap = Object.keys(breakpoints).reduce((acc, name) => {
    acc[name] = breakpoints[name] <= width;
    return acc;
  }, {});

  return {
    width,
    height,
    breakpoints: bpMap,
  };
};
