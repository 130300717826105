import { Box, FlexBox } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Ad from '~/components/Ad';
import Footer from '~/components/Footer';
import Navbar from '~/components/Navbar';
import PageMeta, { defaultMeta } from '~/components/PageMeta';
import Promo from '~/containers/Promo';
import { colors, layout } from '~/utils/css-mixins';

import routesMap from '../../main/routes';
import Logobar from './Logobar';
import Sidebar from './Sidebar';

const AppWrapper = ({ backgroundVariant, ...props }) => (
  <FlexBox
    role="document"
    minHeight="100vh"
    flexDirection="column"
    bg={backgroundVariant === 'secondary' ? colors.bgsiteSecondary : 'white'}
    {...props}
  />
);

const PageContainer = props => (
  <FlexBox
    width="100%"
    alignSelf="center"
    flexDirection={{
      _: 'column',
      xl: 'row',
    }}
    maxWidth={{
      _: layout.oneColumn.maxWidth,
      xl: layout.twoColumn.maxWidth,
    }}
    pt="4"
    px={{
      _: 0,
      sm: layout.oneColumn.pageGutter,
      xl: layout.twoColumn.pageGutter,
    }}
    pb="5,5"
    {...props}
  />
);

const Main = ({ panorama, ...props }) => (
  <FlexBox
    as="main"
    flex={1}
    flexDirection="column"
    minWidth={0}
    pt={{ _: panorama ? 0 : '3,5', xl: 0 }}
    {...props}
  />
);

const Aside = props => (
  <FlexBox
    flexDirection="column"
    order={{ _: -1, xl: 'initial' }}
    width={layout.asideWidth}
    ml={{
      _: undefined,
      xl: layout.twoColumn.columnGutters.xl,
    }}
    {...props}
  />
);

class Page extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  render() {
    const { location } = this.props;
    const route = routesMap[location.type];
    const {
      pageBackgroundVariant,
      pageVariant,
      panoramaEnabled,
      component: PageComponent,
      sidebar: SidebarComponent,
    } = route;

    return (
      <AppWrapper backgroundVariant={pageBackgroundVariant}>
        <PageMeta meta={defaultMeta}>
          <link
            rel="canonical"
            href={`https://travrondenspel.se${location.pathname}`}
          />
        </PageMeta>

        {pageVariant !== 'stripped' && <Navbar />}
        {pageVariant === 'stripped' && <Logobar />}

        <Promo />

        <PageContainer>
          <Main panorama={panoramaEnabled}>
            {panoramaEnabled && (
              <Ad
                deviceFilter="desktopOnly"
                placement="panorama"
                refreshKey={`desktop_${location.pathname}`}
                wrap={content => <Box mb="3,5">{content}</Box>}
              />
            )}

            {panoramaEnabled && (
              <Ad
                deviceFilter="mobileOnly"
                placement="panorama"
                refreshKey={`mobile_${location.pathname}`}
                wrap={content => <Box my="3,5">{content}</Box>}
              />
            )}
            <PageComponent location={location} />
          </Main>

          {pageVariant !== 'stripped' && (
            <Aside hasPanorama={panoramaEnabled}>
              <Ad deviceFilter="desktopOnly" placement="square" />

              {SidebarComponent ? (
                <SidebarComponent />
              ) : (
                <Sidebar location={location} />
              )}
            </Aside>
          )}
        </PageContainer>

        <Footer />
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => ({
  location: state.location,
});

export default connect(mapStateToProps)(Page);
