import { all, takeLeading } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { apiUrl } from '~/utils/request';

export const namespace = 'FrontpageAuthorList';
export const authorIds =
  import.meta.env.VITE_FRONTPAGE_AUTHOR_IDS?.split(',') || [];

export function* getAuthors() {
  yield all(
    authorIds.map(slug =>
      fetchGeneric({
        namespace,
        url: apiUrl(`content/authors/${slug}`),
        maxAge: Number.POSITIVE_INFINITY,
        key: slug,
      }),
    ),
  );
}

export default function* AuthorListSaga() {
  yield takeLeading(['HOME'], getAuthors);
}
