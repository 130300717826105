import { useEffect, useState } from 'react';

import { debounce } from '~/utils';

export default function useWatchScroll({ enabled = true }) {
  const [scroll, setScroll] = useState(window.scrollY);

  useEffect(() => {
    const onScroll = debounce(() => setScroll(window.scrollY));
    if (enabled) {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [enabled]);

  return scroll;
}
