import { Box, Divider, Position } from '@trmediaab/zebra';
import { useMouseLeave, useWindowSize } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectPremium, selectUser } from '~/containers/Auth/selectors';
import useWatchScroll from '~/hooks/useWatchScroll';
import { breakpoints } from '~/utils/media';

import BarContainer from './BarContainer';
import BrandBar from './BrandBar';
import { SCROLL_THRESHOLD, TABLET_BREAKPOINT } from './constants';
import { desktopItems, mobileItems } from './items';
import { DropdownLink, NavLink, SubNavItem } from './NavLink';
import SideItems from './SideItems';
import UserNavigation from './UserNavigation';
import UserMenu from './UserNavigation/UserMenu';

function getBoundingRect(event) {
  const domRect = event.target.getBoundingClientRect();
  const rect = {
    bottom: domRect.bottom,
    left: domRect.left,
    width: domRect.width,
  };

  // For tablet and mobile the header scrolls away, so need to offset for scroll
  // For desktop the header is sticky, so no offset needed
  if (window.innerWidth < breakpoints.desktop) {
    rect.bottom += window.scrollY;
  }

  return rect;
}

const Navbar = ({ user, currentPage, hasPremium }) => {
  const [open, setOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [showRoundsMenu, setShowRoundsMenu] = useState(null);

  const navRef = useRef(null);
  const scrollAmount = useWatchScroll({ enabled: true });
  const windowSize = useWindowSize().width;
  const lessThanTablet = windowSize <= TABLET_BREAKPOINT;
  const mainNavItems = lessThanTablet ? mobileItems : desktopItems;

  useEffect(() => {
    // When opening the UserMobileMenu you can still scroll the <body>
    // setting the body's overflow to "hidden" prevents that
    document.body.style.overflow = open ? 'hidden' : 'auto';
    if (windowSize > TABLET_BREAKPOINT) {
      setOpen(false);
      setDropDownOpen(false);
    }
  }, [open, setDropDownOpen, windowSize]);

  const barContainerRef = useRef();
  const subMenuRef = useRef();
  useMouseLeave([barContainerRef, subMenuRef], () => {
    setShowRoundsMenu(null);
  });

  useEffect(() => {
    const closeSubMenuOnScroll = () => setShowRoundsMenu(null);
    window.addEventListener('scroll', closeSubMenuOnScroll);

    return () => {
      window.removeEventListener('scroll', closeSubMenuOnScroll);
    };
  }, [setShowRoundsMenu]);

  return (
    <Fragment>
      {scrollAmount >= SCROLL_THRESHOLD.mobile && lessThanTablet && !open && (
        <BrandBar
          user={user}
          hasPremium={hasPremium}
          lessThanTablet={lessThanTablet}
          shouldStick
          open={open}
          onClick={() => setOpen(!open)}
        />
      )}

      {!open ? (
        <Position
          ref={navRef}
          position={{ _: 'static', lg: 'sticky' }}
          top="0"
          width="100%"
          zIndex="999"
        >
          <UserNavigation
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
          />
          <BrandBar
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
            scrollAmount={scrollAmount}
            open={open}
            onClick={() => setOpen(!open)}
          />
          <BarContainer
            boxShadow="0 0 0.5em rgb(0 0 0 / 50%);"
            sx={{ position: 'relative' }}
            ref={barContainerRef}
          >
            {mainNavItems.map(({ text, to, items }, index) =>
              to ? (
                <NavLink
                  to={to}
                  key={text}
                  isActive={currentPage === to.type}
                  ml={index === 0 ? { md: '-6px' } : undefined}
                >
                  {text}
                </NavLink>
              ) : (
                <DropdownLink
                  key={text}
                  setSubmenu={setShowRoundsMenu}
                  onMouseEnter={event => {
                    setShowRoundsMenu({ rect: getBoundingRect(event), items });
                  }}
                  onClick={event => {
                    setShowRoundsMenu(
                      showRoundsMenu
                        ? null
                        : { rect: getBoundingRect(event), items },
                    );
                  }}
                >
                  {text}
                </DropdownLink>
              ),
            )}

            <SideItems
              open={dropDownOpen}
              setOpen={() => setDropDownOpen(!dropDownOpen)}
            />
          </BarContainer>

          {showRoundsMenu && (
            <Position.absolute
              ref={subMenuRef}
              top={showRoundsMenu.rect.bottom + 12}
              left={showRoundsMenu.rect.left}
              width={showRoundsMenu.rect.width}
              bg="white"
              pb="1"
              zIndex={1000}
              sx={{ boxShadow: 'rgb(172 172 172 / 50%) 0px 2px 4px' }}
            >
              <Box>
                {showRoundsMenu.items.map(({ text, to }, index) => (
                  <Fragment key={text}>
                    {index !== 0 && <Divider />}

                    <SubNavItem
                      text={text}
                      to={to}
                      onClick={() => setShowRoundsMenu(null)}
                    />
                  </Fragment>
                ))}
              </Box>
            </Position.absolute>
          )}
        </Position>
      ) : (
        <UserMenu closeMenu={() => setOpen(false)}>
          <UserNavigation user={user} hasPremium={hasPremium} />
          <BrandBar
            user={user}
            hasPremium={hasPremium}
            lessThanTablet={lessThanTablet}
            scrollAmount={scrollAmount}
            open={open}
            onClick={() => setOpen(false)}
          />
        </UserMenu>
      )}
    </Fragment>
  );
};

Navbar.propTypes = {
  user: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
  hasPremium: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    user: selectUser,
    currentPage: state => state.location.type,
    hasPremium: selectPremium,
  }),
)(Navbar);
