/* eslint-disable import/no-cycle */
import { Anchor, Button, FlexBox, Position, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import Logo from '../Logo';
import { CloseIcon, MenuIcon } from '../navIcons';
import UserItems from '../UserNavigation/UserItems';

const BrandBar = ({ user, hasPremium, onClick, open, shouldStick }) => (
  <Position
    width="100%"
    bg="primary"
    position={shouldStick ? 'fixed' : 'static'}
    top="0"
    zIndex="9999"
  >
    <FlexBox
      alignItems="center"
      ml="auto"
      mr="auto"
      maxWidth={1360}
      pl={['2', null, '20px']}
      pr={['2', null, '20px']}
      pt={['3', null, '3,5']}
      pb={['3', null, '3,5']}
    >
      <Anchor as={Link} to={{ type: 'HOME' }}>
        <Logo width={{ _: 200, md: 400, lg: 500 }} />
      </Anchor>
      <Position position="relative" display={['none', null, 'block']} ml="auto">
        <UserItems user={user} hasPremium={hasPremium} onClick={onClick} />
      </Position>

      <Button ml="auto" display={['flex', null, 'none']} onClick={onClick}>
        <Text.p fontSize={[0, 1]} pr="1" color="white" fontWeight="bold">
          {!open ? 'MENY' : 'STÄNG'}
        </Text.p>
        {!open ? <MenuIcon $white /> : <CloseIcon $white />}
      </Button>
    </FlexBox>
  </Position>
);

BrandBar.propTypes = {
  user: PropTypes.object,
  hasPremium: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shouldStick: PropTypes.bool,
  open: PropTypes.bool,
};

export default BrandBar;
