import { addDays, subDays } from 'date-fns';
import { all, takeLatest } from 'redux-saga/effects';

import { formatDate } from '~/utils/format';
import { apiUrl } from '~/utils/request';

import { fetchGeneric } from '../GenericFetch/saga';

export function* getRounds({ payload }) {
  const date = payload.date || formatDate(new Date(), 'yyyy-MM-dd');

  const startDate = formatDate(subDays(date, 30), 'yyyy-MM-dd');
  const endDate = formatDate(addDays(date, 7), 'yyyy-MM-dd');

  const betType = payload.bet_type.toUpperCase();

  yield fetchGeneric({
    namespace: 'BetType',
    url: apiUrl('round', {
      ordering: '-start_time',
      limit: 200,
      date_range_after: startDate,
      date_range_before: endDate,
      bet_type: [betType],
    }),
    maxAge: Number.POSITIVE_INFINITY,
    responseHandler: resp => resp.results,
    key: betType,
  });
}

export default function* BetTypeListSaga() {
  yield all([takeLatest('BET_TYPE_LIST', getRounds)]);
}
