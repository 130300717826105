import { all, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { apiUrl } from '~/utils/request';

import { NAMESPACE } from './constants';

export function* getSharedBets() {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('content/shared_bets/travronden_spel'),
    options: { headers: { SITE: 'andelsspel' } },
  });
}

export default function* rootSaga() {
  yield all([takeLatest('SHARED_BETS', getSharedBets)]);
}
