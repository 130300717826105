import PropTypes from 'prop-types';
import styled from 'styled-components';

import Ad from '~/components/Ad';
import ErrorBoundary from '~/components/ErrorBoundary';
import { spacing, stickyStyles } from '~/utils/css-mixins';
import media from '~/utils/media';

const Root = styled.div`
  > * + * {
    margin-top: ${spacing.siteGutter * 2}px;
  }

  ${media.desktop`
    ${stickyStyles(20)}
  `}
`;

const Sidebar = ({ location }) => (
  <ErrorBoundary>
    <Root>
      <Ad
        deviceFilter="desktopOnly"
        placement="sticky"
        refreshKey={`desktop_${location.pathname}`}
      />
    </Root>
  </ErrorBoundary>
);

Sidebar.propTypes = {
  location: PropTypes.object,
};

export default Sidebar;
