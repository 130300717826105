import { all, takeLeading } from 'redux-saga/effects';

import { apiUrl } from '../../utils/request';
import { fetchGeneric } from '../GenericFetch/saga';
import {
  LOAD_PRELIMINARY_EQUIPMENTS,
  LOAD_PRELIMINARY_EQUIPMENTS_BY_RACE,
  NAMESPACE,
} from './constants';

export function* getPreliminaryEquipments({ round }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`round/${round}/preliminary_equipments`),
    maxAge: Number.POSITIVE_INFINITY,
    key: round,
  });
}

export function* getPreliminaryEquipmentsByRace({ race }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${race}/preliminary_equipments`),
    maxAge: Number.POSITIVE_INFINITY,
    key: race,
  });
}

export default function* rootSaga() {
  yield all([
    takeLeading(LOAD_PRELIMINARY_EQUIPMENTS, getPreliminaryEquipments),
    takeLeading(
      LOAD_PRELIMINARY_EQUIPMENTS_BY_RACE,
      getPreliminaryEquipmentsByRace,
    ),
  ]);
}
