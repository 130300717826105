import { put, takeEvery } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { apiUrl } from '~/utils/request';

import { liveFeedVisit } from './actions';
import { LOAD_LIVE_FEED, NAMESPACE } from './constants';

function* getLiveFeed({ payload: { slug, view } }) {
  if (view === 'live') {
    yield put(liveFeedVisit(slug));
  }
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('content/live', { round_slug: slug, limit: 200 }),
    maxAge: Number.POSITIVE_INFINITY,
    key: slug,
  });
}

export default function* rootSaga() {
  yield takeEvery(['ROUND_TIP', LOAD_LIVE_FEED], getLiveFeed);
}
