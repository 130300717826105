import merge from 'deepmerge';
import { produce } from 'immer';

import {
  CLOSE_INTERVIEW_ARCHIVE_SUCCESS,
  FETCH_INTERVIEW_ARCHIVE_FAILURE,
  FETCH_INTERVIEW_ARCHIVE_START,
  FETCH_INTERVIEW_ARCHIVE_SUCCESS,
  LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS,
} from './constants';
import { getInterviewArchive } from './selectors';

const initialState = {
  fetching: false,
  error: undefined,
  start_id: null,
  horse_id: null,
  race_start_time: '',
  page: 1,
  results: [],
  count: 0,
};

const Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CLOSE_INTERVIEW_ARCHIVE_SUCCESS:
        draft.fetching = false;
        draft.error = undefined;
        draft.start_id = null;
        draft.horse_id = null;
        draft.race_start_time = '';
        draft.page = 1;
        draft.results = [];
        draft.count = 0;
        break;

      case FETCH_INTERVIEW_ARCHIVE_START:
        draft.fetching = true;
        draft.error = undefined;
        draft.start_id = action.start_id;
        draft.horse_id = action.horse_id;
        draft.race_start_time = action.race_start_time;
        draft.page = action.page;
        break;

      case FETCH_INTERVIEW_ARCHIVE_SUCCESS: {
        draft.fetching = false;
        draft.error = undefined;
        draft.start_id = action.start_id;
        draft.horse_id = action.horse_id;
        draft.race_start_time = action.race_start_time;
        draft.page = action.page;
        draft.Interview = merge(draft.Interview, {
          [action.race_start_time]: {
            [action.horse_id]: {
              pages: {
                [action.page]: action.results,
              },
              count: action.count,
            },
          },
        });

        const data = getInterviewArchive(draft.Interview, {
          horse_id: action.horse_id,
          race_start_time: action.race_start_time,
          page: action.page,
        });

        if (data === undefined) {
          draft.results = [];
          draft.count = 0;
        } else {
          draft.results = data.results;
          draft.count = data.count;
        }

        break;
      }

      case FETCH_INTERVIEW_ARCHIVE_FAILURE:
        draft.fetching = false;
        draft.error = action.error;
        draft.start_id = null;
        draft.horse_id = null;
        draft.race_start_time = '';
        draft.page = 1;
        draft.results = [];
        draft.count = 0;
        break;

      case LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS: {
        draft.fetching = false;
        draft.error = undefined;
        draft.start_id = action.start_id;
        draft.horse_id = action.horse_id;
        draft.race_start_time = action.race_start_time;
        draft.page = action.page;

        const data = getInterviewArchive(draft.Interview, {
          horse_id: action.horse_id,
          race_start_time: action.race_start_time,
          page: action.page,
        });

        if (data === undefined) {
          draft.results = [];
          draft.count = 0;
        } else {
          draft.results = data.results;
          draft.count = data.count;
        }

        break;
      }
      default:
      // Do nothing
    }
  });

export default Reducer;
