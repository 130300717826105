import {
  FETCH_FEED_FAILURE,
  FETCH_FEED_STARTED,
  FETCH_FEED_SUCCESS,
} from './constants';

export const fetchFeedStarted = () => ({
  type: FETCH_FEED_STARTED,
});

export const fetchFeedSuccess = response => ({
  type: FETCH_FEED_SUCCESS,
  response,
});

export const fetchFeedFailure = error => ({
  type: FETCH_FEED_FAILURE,
  error,
});
