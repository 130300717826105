import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';

import { apiUrl, request } from '../../utils/request';
import {
  closeInterviewArchiveSuccess,
  fetchInterviewArchiveFailure,
  fetchInterviewArchiveStart,
  fetchInterviewArchiveSuccess,
  loadInterviewArchiveCacheSuccess,
} from './actions';
import {
  CLOSE_INTERVIEW_ARCHIVE_START,
  NAMESPACE,
  OPEN_INTERVIEW_ARCHIVE_START,
} from './constants';
import { selectInterviewArchive } from './selectors';

export function* getInterview({ payload: { slug, view, leg_number: leg } }) {
  if (view === 'intervjuer') {
    yield fetchGeneric({
      namespace: NAMESPACE,
      url: apiUrl('content/interviews', {
        round_slug: slug,
        leg,
        limit: 100,
      }),
      maxAge: Number.POSITIVE_INFINITY,
      responseHandler: resp => resp.results,
      key: `${slug}-${leg}`,
      extract_id: data => (data.length > 0 ? data[0].race_id : undefined),
    });
  }
}

export function* openInterviewArchive({
  start_id,
  horse_id,
  race_start_time,
  page,
}) {
  yield fetchInterviewArchive(
    select(selectInterviewArchive, { horse_id, race_start_time, page }),
    call(
      request,
      apiUrl('content/interviews', {
        horse: horse_id,
        start_time_lt: new Date(race_start_time)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' '),
        limit: 3,
        offset: (page - 1) * 3,
      }),
    ),
    start_id,
    horse_id,
    race_start_time,
    page,
    resp => ({
      results: resp.results,
      count: resp.count,
    }),
  );
}

export function* closeInterviewArchive() {
  yield put(closeInterviewArchiveSuccess());
}

export function* fetchInterviewArchive(
  selector,
  callRequest,
  start_id,
  horse_id,
  race_start_time,
  page,
) {
  const exists = yield selector;

  if (exists != null) {
    yield put(
      loadInterviewArchiveCacheSuccess(
        start_id,
        horse_id,
        race_start_time,
        page,
      ),
    );

    return;
  }

  yield put(
    fetchInterviewArchiveStart(start_id, horse_id, race_start_time, page),
  );

  try {
    const response = yield callRequest;

    yield put(
      fetchInterviewArchiveSuccess(
        start_id,
        horse_id,
        race_start_time,
        page,
        response.results,
        response.count,
      ),
    );
  } catch (error) {
    yield put(fetchInterviewArchiveFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest('ROUND_TIP', getInterview),
    takeLatest(OPEN_INTERVIEW_ARCHIVE_START, openInterviewArchive),
    takeLatest(CLOSE_INTERVIEW_ARCHIVE_START, closeInterviewArchive),
  ]);
}
