import { createGenericSelector } from '~/containers/GenericFetch/selectors';

import { CAMPAIGN_NAMESPACE } from './constants';

export const getCampaigns = createGenericSelector(CAMPAIGN_NAMESPACE, 'data');

export const fetchingCampaigns = createGenericSelector(
  CAMPAIGN_NAMESPACE,
  'fetching',
);
