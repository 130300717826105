import { Close } from '@styled-icons/fa-solid';
import {
  Button,
  FlexBox,
  PositionAbsolute,
  PositionFixed,
  Text,
} from '@trmediaab/zebra';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ThemedLink } from '~/components/ThemedLink';
import { selectPremium } from '~/containers/Auth/selectors';
import { getCampaigns } from '~/containers/Promo/selectors';
import config from '~/main/config';

const shouldDisplayPromo = (premiumAccess, location, userUpdated) =>
  config.PREMIUM_TIER_ENABLED &&
  !premiumAccess &&
  !['PRODUCTS_PAGE', 'CHECKOUT_PAGE'].includes(location.type) &&
  userUpdated;

const PromoLink = ({ text, url }) => (
  <ThemedLink
    whiteSpace="nowrap"
    minWidth="fit-content"
    href={url}
    color="white"
    fontWeight="bold"
    backgroundColor="#1C988A"
    px="3"
    py="2"
    sx={{
      borderRadius: '2',
      '&:hover': {
        backgroundColor: '#1C988A80',
        color: 'white',
      },
    }}
  >
    {text}
  </ThemedLink>
);

const PromoContainer = ({ children, inDocumentFlow }) =>
  inDocumentFlow ? (
    children
  ) : (
    <PositionFixed
      left={0}
      bottom={0}
      display="flex"
      width="100%"
      zIndex={9999}
    >
      {children}
    </PositionFixed>
  );

const Promo = ({
  campaign,
  premium_access,
  location,
  userUpdated,
  inDocumentFlow = false,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const handleHide = useCallback(() => setIsHidden(true), []);

  const campaignPromo = campaign?.results?.[0];

  const isPromoVisible =
    shouldDisplayPromo(premium_access, location, userUpdated) &&
    campaignPromo &&
    !(isHidden && !inDocumentFlow);

  if (!isPromoVisible) {
    return null;
  }

  const { headline, links: [link] = [] } = campaignPromo;

  return (
    <PromoContainer inDocumentFlow={inDocumentFlow}>
      <FlexBox
        flex="1"
        flexDirection={inDocumentFlow ? 'column' : { _: 'column', md: 'row' }}
        gap={inDocumentFlow ? undefined : { _: '3', md: '5' }}
        position="relative"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#245767"
        {...(inDocumentFlow
          ? {
              pt: '5',
              pb: '4',
            }
          : {
              py: { _: '5', md: '4' },
            })}
        px="4"
      >
        {inDocumentFlow && (
          <Text
            fontSize="4"
            textDecoration="underline"
            fontWeight="bold"
            color="white"
            textAlign="center"
            maxWidth="400px"
          >
            Lås upp nu: Intervju, tipskommentar & tidigare starter!
          </Text>
        )}

        <Text
          fontWeight="bold"
          color="white"
          textAlign="center"
          fontSize={inDocumentFlow ? '1' : undefined}
          mt={inDocumentFlow ? '3' : undefined}
          mb={inDocumentFlow ? '5' : undefined}
          maxWidth={inDocumentFlow ? '300px' : undefined}
        >
          {headline}
        </Text>

        {link && <PromoLink text={link.text} url={link.url} />}

        {!inDocumentFlow && (
          <PositionAbsolute top={0} right={0}>
            <Button
              onClick={handleHide}
              minWidth="fit-content"
              pt="3"
              pr="2"
              color="white"
            >
              <Close size="16" />
            </Button>
          </PositionAbsolute>
        )}
      </FlexBox>
    </PromoContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  campaign: getCampaigns,
  premium_access: selectPremium,
  userUpdated: state => state.auth.userUpdated,
  location: state => state.location,
});

export default connect(mapStateToProps)(Promo);
