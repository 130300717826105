import { getLogoutUrl } from '~/containers/Auth/utils';
import config from '~/main/config';

export const desktopItems = [
  { text: 'Hem', to: { type: 'HOME' } },
  {
    text: 'Omgångar',
    items: [
      {
        text: 'V75',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V75' } },
      },
      {
        text: 'V86',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V86' } },
      },
      {
        text: 'GS75',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'GS75' } },
      },
      {
        text: 'V65',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V65' } },
      },
      {
        text: 'V64',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V64' } },
      },
    ],
  },
  { text: 'Krönikor', to: { type: 'CHRONICLE_LIST' } },
  { text: 'Nyheter', to: { type: 'NEWS_LIST' } },
  { text: 'TV', to: { type: 'TV_LIST' } },
  { text: 'Andelar', to: { type: 'SHARED_BETS' } },
  { text: 'Resultat', to: { type: 'RESULT' } },
  { text: 'Till start', to: { type: 'ALL_STARTS' } },
];

export const mobileItems = [
  { text: 'Hem', to: { type: 'HOME' } },
  {
    text: 'Omgångar',
    items: [
      {
        text: 'V75',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V75' } },
      },
      {
        text: 'V86',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V86' } },
      },
      {
        text: 'GS75',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'GS75' } },
      },
      {
        text: 'V65',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V65' } },
      },
      {
        text: 'V64',
        to: { type: 'BET_TYPE_LIST', payload: { bet_type: 'V64' } },
      },
    ],
  },
  { text: 'Krönikor', to: { type: 'CHRONICLE_LIST' } },
  { text: 'Nyheter', to: { type: 'NEWS_LIST' } },
  { text: 'TV', to: { type: 'TV_LIST' } },
  { text: 'Andelar', to: { type: 'SHARED_BETS' } },
  { text: 'Resultat', to: { type: 'RESULT' } },
  { text: 'Till start', to: { type: 'ALL_STARTS' } },
];

export const sideItems = [
  {
    text: 'Om Travronden Spel',
    to: { type: 'ABOUT_PAGE', payload: { slug: 'travronden-spel' } },
  },
  {
    text: 'Annonsera',
    to: {
      type: 'PAGE',
      payload: { slug: 'annonsera' },
    },
  },
  {
    text: 'Kundtjänst',
    to: {
      type: 'PAGE',
      payload: { slug: 'FAQ' },
    },
  },
];

export const userDropDownItems = [
  [
    {
      text: 'Läs e-tidningen',
      href: 'https://etidning.travronden.se/',
      external: true,
    },
    {
      text: 'Bevakning',
      href: 'https://www.travronden.se/bevakning/hastar',
      external: true,
    },
  ],
  [
    {
      text: 'Kundtjänst',
      to: {
        type: 'PAGE',
        payload: { slug: 'FAQ' },
      },
    },
    {
      text: 'Mitt TR Media-konto',
      href: config.USERPAGES_URL,
      external: true,
    },
  ],
  [
    {
      text: 'Logga ut',
      href: getLogoutUrl(),
    },
  ],
];
