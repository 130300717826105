import { all, takeLatest } from 'redux-saga/effects';

import { formatDate } from '~/utils/format';
import { apiUrl } from '~/utils/request';

import { fetchGeneric } from '../GenericFetch/saga';

function removeDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function* getMeets({ payload }) {
  const date = payload.date || formatDate(new Date(), 'yyyy-MM-dd');
  const startDate = formatDate(removeDays(date, 7), 'yyyy-MM-dd');

  yield fetchGeneric({
    namespace: 'Result',
    url: apiUrl('meet', {
      ordering: 'start_time',
      limit: 200,
      start_date: startDate,
      end_date: date,
      bet_type: ['V75', 'V86', 'GS75', 'V65', 'V64', 'V4'],
    }),
    maxAge: Number.POSITIVE_INFINITY,
    responseHandler: resp => resp.results,
    key: `meet_list_${date}`,
  });
}

export function* getMajorRoundResults() {
  const date = formatDate(new Date(), 'yyyy-MM-dd');
  const startDate = formatDate(removeDays(date, 7), 'yyyy-MM-dd');

  yield fetchGeneric({
    namespace: 'Result',
    url: apiUrl('round', {
      ordering: 'start_time',
      limit: 50,
      date_range_after: startDate,
      date_range_before: date,
      bet_type: ['V75', 'V86', 'GS75', 'V65', 'V64', 'V4'],
    }),
    maxAge: Number.POSITIVE_INFINITY,
    responseHandler: resp => resp.results,
    key: `majorRoundResult`,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest('RESULT', getMeets),
    takeLatest('RESULT', getMajorRoundResults),
  ]);
}
