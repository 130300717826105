import 'react-datepicker/dist/react-datepicker.css';

import { createGlobalStyle, css } from 'styled-components';

import { colors, fonts } from '~/utils/css-mixins';

const defaultStyles = css`
  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('/fonts/guardianegypweb-semibold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('/fonts/guardianegypweb-semibold-it.woff') format('woff');
    font-style: italic;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('/fonts/guardianegypweb-light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('/fonts/guardianegypweb-light-it.woff') format('woff');
    font-style: italic;
    font-weight: 300;
    font-stretch: normal;
  }

  html {
    -ms-overflow-style: scrollbar;
    line-height: 1.4;
    font-size: 16px;
  }

  body {
    font-family: ${fonts.primary};
    background: ${colors.primary};
    color: ${colors.bodyText};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    text-rendering: optimizelegibility;
  }

  p + p {
    margin-top: 1.5rem;
  }

  b,
  strong {
    font-weight: 600;
  }

  i,
  em {
    font-style: italic;
  }

  a {
    color: ${colors.link};
    text-decoration: none;
    transition: all 200ms ease;
  }

  th {
    font-weight: 600;
  }
`;

const reactDatePickerPopper = css`
  .react-datepicker-popper {
    .react-datepicker {
      border-color: ${colors.border};
      font-family: inherit;
    }

    .react-datepicker__header {
      border-bottom-color: ${colors.border};
      background-color: ${colors.bgsitecomplementary};
    }

    .react-datepicker__current-month {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background-color: ${colors.primary};
    }

    .react-datepicker__day--outside-month {
      color: ${colors.textmeta};
    }

    .react-datepicker .react-datepicker__triangle {
      left: 50%;
    }
  }
`;

export default createGlobalStyle`
  ${defaultStyles}
  ${reactDatePickerPopper}
`;
